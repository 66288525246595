

<footer>
    <div class="content-footer">
        <div class="content-logo">
            <div class="item-footer logo"></div>
        </div>
        <div class="item-footer container-menu">
            <ul>
                <li *ngFor="let item of menuData">
                    <a [href]="item.link">{{item.label}}</a>
                </li>
            </ul>
        </div>
    
        <div class="item-footer content-socials">
            <div class="title">
                <h3>Follow Us</h3>
            </div>
            <ul>
                <li *ngFor="let item of socialsData">
                    <a [href]="item.link">
                        <div class="img-social"><img [src]="item.icon.url" alt=""/></div> 
                        {{item.label}}
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="copyright">
        <p>{{copyright}}</p>
    </div>
</footer>

<div class="footer-link">
    <ul>
        <li *ngFor="let item of footerLink">
            <a [href]="item.link">{{item.label}}</a>
        </li>
    </ul>
</div>