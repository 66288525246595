

<app-header></app-header>

<div id="page-container">
    <router-outlet (activate)="whenRouteActivate()"></router-outlet>
</div>

<!--Login Modal-->
<div class="container-login">
    <amg-login-modal 
        [nameLoginBtn]="'login' | commonLabel | async"
        [isUsernameLabel]="true"
        [usernameLabel]="'email' | commonLabel | async"
        [emailPlaceHolder]="'email_placeholder' | commonLabel | async"
        [isPasswordLabel]="true"
        [passwordLabel]="'password' | commonLabel | async"
        [passwordPlaceHolder]="'password_placeholder' | commonLabel | async"
        [forgotPwd]="'forgot_password' | commonLabel | async"
        [forgotPwdUrl] = "forgotPwdUrl"
        [isRegisterDescription]="true"
        [registerLink] = "registrationLink"
        [nameRegisterBtn] = "'registration_modal' | commonLabel | async"
        [createAccount]="'have_account' | commonLabel | async"
        [errorServer] = "errorServer"
        (onSubmit)="onLoginForm($event)">
    </amg-login-modal>
</div>

<app-footer></app-footer>