export const environment = {
  production: false,

  google: {
    analyticsId: ''
  },

  myAccount:{
    url:'https://devspflpayments.streamamg.com/account/'
  },

  registration:{
    url:'https://devspflpayments.streamamg.com/account/freeregistration?lang=en'
  },

  forgot:{
    url:'https://spflpayments.streamamg.com/account/reset'
  },

  streamPlay: {
    url: 'https://staging.api.streamplay.streamamg.com/fixtures/football/p/3001441',
    contentPageSize: 10
  },

  cloudPay: {
    scriptUrl: 'https://spflpayments.streamamg.com/_resx/js/stream-payments.js',
    jQueryUrl: 'https://spflpayments.streamamg.com/Assets/Account/js/jquery.min.1.9.1.js'
  },

  cloudMatix: {
    url:
        'https://spfl-cm.streamamg.com/api/v1/a0da2683-0de4-4898-859d-6f4c1c2a25f2/6g4800WSpFBOHLUusUBc1Cfb2tJhQdo187PMox7Z6ifoBv8p9W/en/feed/ce1754de-63b8-48c4-913c-060de2c7d947/sections/?section=3d4ea580-346c-4abd-9420-4c564b2ba142'
  },

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001441',
    uiConfId: '30028777',
    flashvars: {
      'playerSeekButtonAMG.plugin': true,
      'currentTimeLabel.order': 10,
      'liveStatus.order': 11,
      'durationLabel.order': 12,

      'playPauseBtn.order': 40,

      'volumeControl.order': 100,
      'chromecast.order': 80,
      'sourceSelector.order': 70,
      IframeCustomPluginCss1: '/_assets/css/video-player.css'
    },
  },

  wordpress: {
    api: 'https://wp-devspfl.streamamg.com/wp-json/wpa/v1',
    pages: {
      home: '/section?slug=home',
      fixtures: '/section?slug=fixtures',
      browse: '/section?slug=browse',
      support: '/section?slug=support',
      teams: '/section?slug=teams',
      subscribe: '/section?slug=subscribe',
      video: '/section?slug=video',
    },
    commonData: '/common_field?slug=common-fields'
  }
};
