import { Pipe, PipeTransform } from '@angular/core';
import { SupportService } from '@app/pages/support/services/support.service';

@Pipe({
  name: 'supportLabel'
})
export class SupportLabelPipe implements PipeTransform {

  constructor(private supportService: SupportService) { }
  transform(key: string, ...args: string[]): string {
    const found: any = this.supportService.labels.find(x => x.key == key);
    return found ? found.label : '';
  }
}
