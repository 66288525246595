
<header>
    <div class="content-logo">
        <div class="logo"><a [routerLink]="'/home'"></a></div>
    </div>
    <div class="container-menu" [ngClass]="{'open-menu': menuState}"> 
        <div class="close-menu" (click)="toggleMenu()"></div>
        <ul>
            <li *ngFor="let item of commonData">
                <a [routerLink]="item.link" routerLinkActive="active" (click)="toggleMenu()">{{item.label}}</a>
            </li>
        </ul>
        <div class="icon-menu mobile">
            <ng-container *ngIf="!isLoggedIn; else noLoggedIn">
                <amg-login-btn></amg-login-btn>
            </ng-container>
            <amg-search-btn></amg-search-btn>
        </div>
    </div>
    
    <div class="icon-menu">
        <button (click)="toggleMenu()" class="hamburger">
            <span>toggle menu</span>
        </button>
    </div>

    <ng-template #noLoggedIn>
        <div class="my-account-link"><a [href]="myAccountUrl">My Account</a></div>
            
        <div class="content-logout">
            <span (click)="doLogout($event)">Logout</span>
        </div>
    </ng-template>
    
    <!--Search Modal-->
    <amg-search-modal
        [iconSearchColor]="iconSearchColor"
        [placeholder]="'search_placeholder' | commonLabel | async"
        (onSearch)="onValueSearch($event)"
        [filterSearchTemplate]="filterSearchTemplate" 
    ></amg-search-modal>

    <ng-template #filterSearchTemplate>
        <div class="content-filter-search">
            <div class="filter-box" *ngFor="let item of filterSearchData; let idx = index;">
                <a class="search-button" 
                    (click)='applySearch(item)'  
                    [ngClass]="{'mark-selected': markSelected == item.key}">
                    {{item.label}}
                </a>
            </div>
        </div>
    </ng-template>

</header>
