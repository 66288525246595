import { 
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Inject
} from '@angular/core';
import { CloudPayService } from '@streamamg/cloud-pay-lib';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  errorServer = '';
  @Output() onSubmit = new EventEmitter<any>();
  public registrationLink = environment.registration.url;
  public forgotPwdUrl = environment.forgot.url;

  //Login Model
  isOpened = false;

  constructor(
    private cd: ChangeDetectorRef, 
    private cloudPayService: CloudPayService,
    ){
      
  }

  onLoginForm(term): void{
    this.onSubmit.emit(term);
    this.errorServer = "";

    const dataLogin = {
      emailaddress: term.emailaddress,
      password: term.password
    };

    this.cloudPayService.login(dataLogin, (res) => {
      if (!res.ModelErrors) {
        this.isOpened = false;
      } else {
        Object.keys(res.ModelErrors).forEach(field => {
          this.errorServer = res.ModelErrors[field];
        });
      }
      this.cd.markForCheck();
    });
  }

  // When route is activated, and page load, it should scroll to the top of the page
  public whenRouteActivate = () => {
    window.scroll(0,0);
  }
}
