import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  public isOpen = new EventEmitter<boolean>();
  public labels: any;
  constructor() { }
}
