import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { CloudPayService } from '@streamamg/cloud-pay-lib';
import { WordpressService } from '@streamamg/wordpress-lib';
import { environment } from '../../../../environments/environment';
import { SubSink } from 'subsink';
import { ModalService } from '@streamamg/amg-common';
import { Filters } from '../../../pages/browse/types/filters.type';

export interface WpFilterSearch{
  label: string;
  key: string;
  vaue: string;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  //menuState:string = 'out';
  public myAccountUrl: string = environment.myAccount.url;
  public menuState: boolean = false;
  public subSink = new SubSink();
  private commonDataUrl: string;
  public commonData: any;
  public commonLabels: { [key: string]: string };
  public iconSearchColor: string = '#001E5D';
  public isLoggedIn;
  public filterSearchData: WpFilterSearch;
  public markSelected: string = '';
  public params: Filters;

  constructor(
    private wordpressService: WordpressService, 
    private modalService: ModalService,
    private router: Router,
    private cloudPayService: CloudPayService,
    private cd: ChangeDetectorRef) {
    this.commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
    this.wordpressService.init(environment.wordpress.api, this.commonDataUrl);
    this.isLoggedIn = false;
    this.params = {};
   }

  ngOnInit(): void {
    this.getSearchTags();
    this.cloudPayService.getCloudPayState().subscribe(userState => {
      this.isLoggedIn = userState.isLoggedIn;
      this.cd.markForCheck();
    });
    this.getCommonData();
    
  }

  toggleMenu(): void{
    this.menuState = this.menuState === false ? true : false;
  }

  closeModel() {
    this.modalService.closeModal("Search");
    this.cd.markForCheck();
  }

  applySearch(item) {
    this.params.contentType = item.value;
      this.router.navigate(['browse', this.params]);
      this.closeModel();
      this.cd.markForCheck();
  }

  private getCommonData(): void {
    this.subSink.add(
      this.wordpressService.getCommonData<any>()
        .subscribe(data => { 
          if (data) {
            this.commonData = data.menu;
            //this.filterSearchData = data.filter_search;
            this.commonLabels = data.labels;
            this.cd.markForCheck();
          }
        })
    );
  }

  getSearchTags(){
    this.wordpressService.init(environment.wordpress.api, this.commonDataUrl);
    const pathSug = `${environment.wordpress.pages.browse}`;
    this.subSink.add(
      this.wordpressService
        .getPageData(pathSug)
        .subscribe((value: any) => {
          if (value && value.browse) {
            this.filterSearchData = value.browse.filters.pre_defined;
            this.cd.markForCheck();
          }
        })
    );
  }

  public doLogout(event) {
    event.preventDefault();
    this.toggleMenu();
    this.cloudPayService.logOut();
  }

  onValueSearch(search){
    this.params = {};
    if(search){
      this.params.search = search;
      this.router.navigate(['browse', this.params]);
      this.cd.markForCheck();
    }
  }
}
