import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule)
  },
  {
    path: 'fixtures',
    loadChildren: () => import('./pages/fixtures/fixtures.module')
      .then(m => m.FixturesModule)
  },
  {
    path: 'browse',
    loadChildren: () => import('./pages/browse/browse.module')
      .then(m => m.BrowseModule)
  },
  {
    path: 'club-highlights',
    loadChildren: () => import('./pages/teams/teams.module')
      .then(m => m.TeamsModule)
  },
  {
    path: 'live', // Video - Live
    loadChildren: () => import('./pages/video/video-live/video-live.module')
      .then(m => m.VideoLiveModule)
  },
  {
    path: 'video', // Video - VOD
    loadChildren: () => import('./pages/video/video-vod/video-vod.module')
      .then(m => m.VideoVodModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/support/support.module')
      .then(m => m.SupportModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./pages/subscribe/subscribe.module')
      .then(m => m.SubscribeModule) 
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
