import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { UserAuthLibModule } from '@streamamg/forms-lib';
import { SearchLibModule } from '@streamamg/elements-lib';
import { SharedModule } from './shared/shared.module';
import {CloudPayModule} from '@streamamg/cloud-pay-lib';
import {environment} from '@environments/environment';
import {WINDOW_PROVIDER} from '@streamamg/amg-common';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    UserAuthLibModule,
    SearchLibModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CloudPayModule.forRoot(
        {
          url: environment.cloudPay.scriptUrl,
          jQueryUrl: environment.cloudPay.jQueryUrl,
          language: 'en',
          isSso: false
        },
        WINDOW_PROVIDER
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
