import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommonLabelPipe} from './pipe/common-label.pipe';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
//import { BrowseLabelPipe } from './pipe/browse-label.pipe';
import { SupportLabelPipe } from './pipe/support-label.pipe';

@NgModule({
  declarations: [
    CommonLabelPipe,
    SafeHtmlPipe,
    //BrowseLabelPipe,
    SupportLabelPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonLabelPipe,
    SafeHtmlPipe,
    //BrowseLabelPipe,
    SupportLabelPipe
  ]
})
export class SharedModule { }
