import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { WordpressService } from '@streamamg/wordpress-lib';
import {environment} from '../../../../environments/environment';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  subSink = new SubSink();
  private commonDataUrl: string;
  commonData: any;
  menuData: any;
  socialsData: any;
  copyright: string;
  footerLink: any;

  constructor(private wordpressService: WordpressService, private cd: ChangeDetectorRef) { 
    this.commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
    this.wordpressService.init(environment.wordpress.pages.home, this.commonDataUrl)
  }

  ngOnInit(): void {
    this.getCommonData();
  }

  private getCommonData(): void {
    this.subSink.add(
      this.wordpressService.getCommonData<any>()
        .subscribe(data => { 
          if (data) {
            this.menuData = data.footer_menu;
            this.socialsData = data.footer_socials;
            this.copyright = data.footer_copyright;
            this.footerLink = data.footer_link;

            this.cd.markForCheck();
          }
        })
    );
  }

}
